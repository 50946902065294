<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#toggleActivationModal"
    id="toggleActivationModalBtn"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="toggleActivationModal"
    tabindex="-1"
    aria-labelledby="toggleActivationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="toggleActivationModalLabel">
            user {{ isActive ? "deactivation" : "activation" }} confirmation
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Do you realy want to {{ isActive ? "deactivate" : "activate" }} this
          user ?
        </div>
        <div class="modal-footer">
          <button
            v-if="!in_submission"
            type="button"
            class="btn btn-primary"
            @click="toggleActivation()"
          >
            {{ isActive ? "deactivate" : "activate" }}
          </button>
          <div
            v-if="in_submission"
            class="spinner-border text-primary m-5"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            type="button"
            class="btn btn-white"
            data-bs-dismiss="modal"
            id="toggleActivationModalClose"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "toggleActivation",
  props: {
    userId: {
      required: true,
      type: [Number, null],
    },
    isActive: {
      required: true,
      type: [Boolean, null],
    },
    fetchUsers: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      in_submission: false,
    };
  },
  methods: {
    async toggleActivation() {
      this.in_submission = true;
      await axiosConfig
        .get(`api/toggleActivation/${this.userId}`)
        .then((res) => {
          console.log(res);
          this.fetchUsers({});
          document.getElementById("toggleActivationModalClose").click();
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
